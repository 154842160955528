import _createWorker from "./createWorker";
var exports = {};
const createWorker = _createWorker;
const recognize = async (image, langs, options) => {
  const worker = await createWorker(options);
  await worker.loadLanguage(langs);
  await worker.initialize(langs);
  return worker.recognize(image).finally(async () => {
    await worker.terminate();
  });
};
const detect = async (image, options) => {
  const worker = await createWorker(options);
  await worker.loadLanguage("osd");
  await worker.initialize("osd");
  return worker.detect(image).finally(async () => {
    await worker.terminate();
  });
};
exports = {
  recognize,
  detect
};
export default exports;