import _getId from "./utils/getId";
var exports = {};
const getId = _getId;
let jobCounter = 0;
exports = ({
  id: _id,
  action,
  payload = {}
}) => {
  let id = _id;
  if (typeof id === "undefined") {
    id = getId("Job", jobCounter);
    jobCounter += 1;
  }
  return {
    id,
    action,
    payload
  };
};
export default exports;