import * as _runtime2 from "regenerator-runtime/runtime";
var _runtime = "default" in _runtime2 ? _runtime2.default : _runtime2;
import _createScheduler from "./createScheduler";
import _createWorker from "./createWorker";
import _Tesseract from "./Tesseract";
import _languages from "./constants/languages";
import _OEM from "./constants/OEM";
import _PSM from "./constants/PSM";
import _log from "./utils/log";
var exports = {};
/**
 *
 * Entry point for tesseract.js, should be the entry when bundling.
 *
 * @fileoverview entry point for tesseract.js
 * @author Kevin Kwok <antimatter15@gmail.com>
 * @author Guillermo Webster <gui@mit.edu>
 * @author Jerome Wu <jeromewus@gmail.com>
 */
_runtime;
const createScheduler = _createScheduler;
const createWorker = _createWorker;
const Tesseract = _Tesseract;
const languages = _languages;
const OEM = _OEM;
const PSM = _PSM;
const {
  setLogging
} = _log;
exports = {
  languages,
  OEM,
  PSM,
  createScheduler,
  createWorker,
  setLogging,
  ...Tesseract
};
export default exports;
const _languages2 = exports.languages,
  _OEM2 = exports.OEM,
  _PSM2 = exports.PSM,
  _createScheduler2 = exports.createScheduler,
  _createWorker2 = exports.createWorker,
  _setLogging = exports.setLogging;
export { _languages2 as languages, _OEM2 as OEM, _PSM2 as PSM, _createScheduler2 as createScheduler, _createWorker2 as createWorker, _setLogging as setLogging };