import * as _isElectron2 from "is-electron";
var _isElectron = "default" in _isElectron2 ? _isElectron2.default : _isElectron2;
var exports = {};
const isElectron = _isElectron;
exports = key => {
  const env = {};
  if (typeof WorkerGlobalScope !== "undefined") {
    env.type = "webworker";
  } else if (isElectron()) {
    env.type = "electron";
  } else if (typeof window === "object") {
    env.type = "browser";
  } else if (typeof process === "object" && true) {
    env.type = "node";
  }
  if (typeof key === "undefined") {
    return env;
  }
  return env[key];
};
export default exports;